import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const WebsiteDesignSEOServicePage = () => {
  return (
    <PageLayout>
      <SEO
        title="Back End Developer - Website Backend - Websuasion"
        ogDescription="Where does a Back End Developer fit into your development team? From the database to the API, we'll show you how back end developers program your app logic."
        image="/images/development/back-end-developers.jpg"
      />
		  <TextBlock
		    textPadded
		    textLeft={
		      <div>
						<h1>Back End Developers</h1>
						<p>
							Back End Development happens on cloud servers.
						</p>
						<p>
							The back-end consists of:
						</p>
						<ul>
						 	<li>The operating system</li>
						 	<li>Database</li>
						 	<li>Web server</li>
						 	<li>Application logic</li>
						 	<li>Implementation code</li>
						 	<li>Digital assets (graphics, audio, and video)</li>
						</ul>
						<p>
							The part users touch in a web browser or mobile app is the <a href="/development/front-end-web-developer/">user interface</a>. But, what happens after the user clicks? The web browser then sends data and instructions to the server. This is where the Back-end Developer takes the wheel.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
		      </div>
		    }
		    textRight={
		      <div>
					<img src="/images/development/back-end-developers.jpg" className="rounded-image" alt="Back End Developer - Website Backend - Custom Software - Websuasion" />
		      </div>
		    }
		  />
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								The back-end is where most of the work on your data happens. The user interface allows a user limited control over what happens on the back end. But the server does a lot more than react to user actions. There is a lot of logic on the server side that runs without the user's knowledge. Automated tasks will run based on time of day. For example, the system may send out payment reminders when accounts are coming due. The back end will generate notifications like email and SMS text. It can generate PDF or CSV downloads. Using complex algorithms, it aggregates your business metrics. The server provides you with the executive reporting that help you make executive decisions.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Why A Back End Developer Uses Dynamic vs. Compiled Languages</h2>
							<p>
								Back End Developers use dynamic languages like Ruby, Python, JavaScript or PHP. They may also use compiled languages like Java or C#. A dynamic language processes code in real time. A compiled language first goes through a build process. It is then deployed and run.
							</p>
							<p>
								The frequency of change and fast iterative deployment is often your primary concern. So, dynamic languages are the initial choice. The cost of development and cost of change is lower with dynamic programming. But, dynamic languages do need more server resources and are slower. Systems with intense bandwidth may look to compiled languages. Compiled apps provide greater processing speed with lower resource requirements.
							</p>
							<p>
								Most applications start out using dynamic server-side code. As the dynamic systems grow, bottlenecks may arise. Back End Developers can then move these tasks off to compiled micro-applications. This is a pragmatic development approach. You can't guess how your users will use your system. And, you don't know where the issues are going to arise. Performance monitoring reveals issues before they become detrimental. Back End Developers then react to potential bottlenecks. Your system changes with minimal interruption.
							</p>
							<FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Programming Frameworks Used By The Back End Developer</h2>
							<p>
								Programming languages are often confused with Programming Frameworks. For instance, we are a Ruby Programming Language firm. Because of that, people assume we work in the Ruby on Rails framework. We can, but rarely do. Ruby on Rails developed a media reputation that outshined the language beneath it. Ruby itself is where the magic happens.
							</p>
							<h3>Pros of Frameworks</h3>
							<p>
								Server Side Frameworks give developers a lot of base functionality out of the box. Ruby on Rails, Django and Code Igniter are popular choices. They can be very attractive, especially to beginning programmers. They solve common issues and lean on convention over configuration. They tend to have a lot of community support and guidance. They are a great tool for learning good programming patterns. And they enable a single programmer to achieve a lot of functionality. Developer specialization isn't required. As long as they stick to the conventions, it usually works.
							</p>
							<h3>Cons of Frameworks</h3>
							<p>
								Inexperienced programmers often get tied to their frameworks of choice. They have not yet developed enough experience with the base language. They lack understanding of the code that lies beneath the framework. This hampers their problem-solving ability. Frameworks also suffer from code bloat. They try to solve everyone's problems, so they load a lot of underlying code - need it or not.
							</p>
							<h3>Framework Lock-in</h3>
							<p>
								And then there is framework lock-in. This is the most costly for businesses. As the framework changes, you have to keep up with it. If you don't, you may end up with unsupported features that break. After falling too many versions behind, an upgrade may not be an option. So, you are once again rebuilding your otherwise functioning application. Sometimes you have no way out of the lock-in. Apple and Android ecosystems are an example. If you want to play ball in their app stores, you have to keep up with their changes. Plan for inevitable quarterly development costs.
							</p>
							<h2>Selected Libraries</h2>
							<p>
								With back-end development, we prefer libraries over frameworks. A framework tries to solve all problems in one package. A library solves a single issue. Developers can select the best library to solve your specific problem. They wire it together with the rest of your system. In a sense, this creates your own decoupled framework. But, the code is lean and chosen for <a href="../it-consulting-business-solutions/">your niche purpose</a>. We track the activity of <a href="https://en.wikipedia.org/wiki/Open-source_software">open-source projects</a> to ensure frequent updates. If abandoned, we replace it with another option. In the process, other parts of your system remain unchanged.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>WordPress ... it's for marketing</h2>
							<p>
								Let's talk <a href="https://en.wikipedia.org/wiki/WordPress">Wordpress</a> for a second. First, it is not a framework. It's a marketing platform. A <a href="../digital-marketing-agency-branding-firm/">great marketing platform</a>, in fact. But it was never intended as a base for a web application. That way lies dragons.
							</p>
							<p>
								Often, web designers will augment Wordpress for their clients. They go beyond its intent building out client portals, event systems, and all manner of things. This is where businesses get into trouble. The Wordpress database structure is not conducive to the requirements of web applications. Web apps need optimized queries working through custom associations. The stock Wordpress method of customization doesn't have this. You end up doing many queries and using logic-based data-churning. When you have a handful of clients, it seems to work fine. But when you get dozens, it runs like molasses. Your website doesn't scale.
							</p>
							<p>
								Some may argue this point. A backend programmer could write custom tables. They could write optimized queries within the Wordpress ecosystem. True. But at that point, you aren't benefiting from the system. And, you again suffer the <a href="http://silentrob.me/articles/framework-lockin">lock-in issues</a> mentioned above. One WordPress update that breaks your code will have you seething ... and writing checks.
							</p>
							<p>
								To use Wordpress for apps conflates two activities: marketing versus your day-to-day process. Yes, there should be communication between those activities. But, they shouldn't be the same system.
							</p>
							<FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Version Control Systems</h2>
							<p>
								<a href="https://git-scm.com/book/en/v2/Getting-Started-About-Version-Control">Version control systems</a> track changes to your codebase over time. The most common and robust of these systems is <a href="https://git-scm.com/">Git</a>. Git allows programmers to record every change to every line of code in your application. Git records this for the entire history of your app's development. It tells you exactly who made each change, on what date, at what time, and from what branch it came.
							</p>
							<p>
								Branching is an approach of quarantining code changes. Developers usually branch by functionality. Once that code passes test and quality assurance, it gets merged. Developers often have little branches for every ticket request in your development cycle. There will also be a developer branch for the project manager to approve changes. Another branch will be for quality assurance. That QA branch is where you, as the stakeholder, will test and approve changes. And the production branch is the live version in use by your customers.
							</p>
							<h2>The Application</h2>
							<p>
								We like to put all the business logic into one central part of your system. We call this "The Application". This holds your Entities - the nouns of your system. It holds your Services - the verbs which act on those entity nouns. It holds your Representers - the Entity filters. Representers control access to your Entities under various circumstances. The application connects and configures all the foundational code in your system.
							</p>
							<h2>Implementations</h2>
							<p>
								Different user-types interact with your system in different ways. We like to separate these into what we call Implementations. You might have a public website implementation. This is for what logged-out users and search engines can see. You will have an Admin web portal implementation for you and your staff to manage the system.
							</p>
							<p>
								You may also have any number of customer-focused implementations: Distributors, Partners, and Customers. If you have a mobile app, the Mobile API will be yet another implementation. Back End Developers design it to communicate data back and forth to your iOS and Android app.
							</p>
							<p>
								Implementations consist of routing, error handling, view logic, templates, and assets. This is the point in web apps where the front-end developer and back-end developer cross paths. Implementations allow both to use the central business logic in different contexts. We write that core logic once and maintain it in one place.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Cost Savings Through Code Patterns</h2>
							<p>
								The majority of application development cost is in the core business logic. This organizational approach eliminates code duplication and keeps maintenance cost low. It keeps friction low when adding new functionality. And it prevents changes in one implementation context from affecting the other implementations. For instance, let's say you add a new feature for your administrators. That addition will in no way impact the customer experience.
							</p>
							<h2>Test-First and Behavioral-Driven Development</h2>
							<p>
								We use the practice of <a href="https://en.wikipedia.org/wiki/Test-driven_development">test-first development</a>. This means we first write code to test code that we haven't even written yet. Sounds crazy right?
							</p>
							<p>
								It's actually beneficial in many ways. It captures the specific functionality and behavior intent provided by the system engineer. The system engineer puts a lot of time in with you and your staff at the beginning of the project. Together, you and the engineer determine the best application architecture for you. Our developers write these tests to reflect that architecture. A <a href="http://rspec.info/">test suite</a> might comprise thousands of tests. It can run in minutes testing every single expectation.
							</p>
							<h3>The Back End Developer Next Action List</h3>
							<p>
								At the beginning of development, all those tests fail. Remember, we haven't written any of the actual code yet. So the first test that fails tells the development team the next action they need to take. It's like a massive to-do list that points the developer in the right direction. Once all the tests pass, their job is complete.
							</p>
							<h3>Protector of Your Code</h3>
							<p>
								Once passing tests are in place, the test suite takes on a new role - of protector. If a new code addition breaks any of the previous code, the tests raise the warning flag. This allows developers to be fearless when trying new code ideas. They don't have to worry about something breaking without their knowledge.
							</p>
							<h3>Self-Documenting Code of the Back End Developer</h3>
							<p>
								Tests also act as documentation. No programmer is going to remember the intent of code they wrote 5 years ago. But, they can look to the tests and understand immediately what the purpose of that code is. Any development team worth its salt can pick up a project and understand what is happening. So long as there is good test coverage.
							</p>
							<h3>A little cost now. A lot of savings later.</h3>
							<p>
								There is very little downside to test-first development. You pay a little more in upfront cost, but you save many times that down the road. Like application architecture, we don't give you the option of not doing it. It's the right way to do development.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default WebsiteDesignSEOServicePage
